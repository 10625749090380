<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Create & Edit Rubrics</h3>

    <p>Framework items (standards, components, competencies, etc.) may be associated with a rubric to communicate expectations of quality around a task, product, or performance and/or used to delineate consistent criteria for grading.
        <ul>
            <li><strong>TIP:</strong> To add a rubric to one or more items within a framework that is already public, a best practice is to create a sandbox copy of the framework until rubric revisions are complete. Read more about how to manage a sandbox copy of a framework <span v-html="link('create_sandbox', 'here')"></span>.</li>
        </ul>
    </p>

    <p><strong>Enable editing in order to create or revise a rubric:</strong>
        <ol>
            <li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> with a System or Framework Admin or Editor access (Shared Host plan and Dedicated Host plan subscribers only - see <span v-html="link('admin', 'Admin Functions')"></span>)</li>
            <li>Open a framework (subject area) you want to add a rubric to by selecting the framework title or tile.</li>
            <img srcset="/docimages/framework_toolbar.png 2x" class="k-help-img block">
            <li>From the framework toolbar select the EDIT FRAMEWORK icon to enable edit mode.
                <ul>
                    <li>The <v-icon small>fa-solid fa-pen-to-square</v-icon> edit icon will turn black and have a circle to indicate editing is enabled.</li>
                </ul>
            </li>
            <li>From the tree view of the framework, click the item to reveal the item card on the right side of the screen. </li>
        </ol>
    </p>

    <p><strong>CREATE A RUBRIC</strong>
        <ul>
            <li>Select the <img srcset="/docimages/green_edit_icon.png 4x"> colored edit icon (color will vary by framework). In the edit menu, click CREATE/COPY/IMPORT and choose CREATE RUBRIC (BETA).</li>
            <img srcset="/docimages/new_rubric_beta.png 1x" class="k-help-img block">
            <li>A <strong>New Rubric</strong> window will appear with edit icons and fields to click on for more options. There is also an IMPORT RUBRIC button if you’d prefer to create the rubric via an import. To manually create or edit a rubric:</li>
            <ul>
                <li>Enter a <strong>Rubric Title</strong> and your text will replace the New Rubric text at the top of the window.</li>
                <li>Read the <strong>Rubrics Metadata</strong> Fields section below for tips about how to use each rubric metadata field available. </li>
                <li>Choose the DONE EDITING button to save the rubric.</li>
                <li>Select the X icon to close the rubric window.</li>
            </ul>
        </ul>
    </p>

    <p><strong>RUBRIC METADATA FIELDS</strong></p>
    <p>The following fields are available for editing:</p>
    <p><u>Rubric document level metadata</u>
        <ul>
            <li><strong>Rubric Title (required):</strong> is viewable when clicking on an item’s card or from the <strong>Destination Item</strong> column in the <strong>Associations</strong> table view (when the Has Rubric association type is visible).</li>
            <img srcset="/docimages/framework_associations.png 4x" class="k-help-img block">
            <li><strong>Rubric Description:</strong> provides an overview or explanation of the rubric’s intent and application. This field could include general information about what the rubric is for, who it is intended for, and how it should be used.</li>
            <li><strong>Rubric Notes:</strong> gives context, guidance, or specific instructions for using or interpreting the rubric. Information could include details about scoring, clarifications for specific criteria, or tips for assessors.</li>
        </ul>
    </p>

    <p><u>Rubric item level metadata</u>
        <ul>
            <li><strong>Criteria (criterion description):</strong> are represented as the rows of the rubric. The row can be the category, strand, domain, standard, or component from a framework that a rubric is designed to evaluate. The criterion description serves as a label for the row of the rubric
                <ul>
                    <li>For a holistic rubric with a single row, the criterion description might be the human readable code of the item in the framework that the rubric is associated to (standard, component, or competency).</li>
                    <li>For a multi-dimensional rubric, there may be many rows of criteria (writing, presentation, and project rubrics for example).</li>
                </ul>
            </li>
            <li><strong>Criterion level (header):</strong> appear across the top of the columns as scores and/or labels for the rubric levels. Each level has edit icons that allow the editor to adjust the level score and the <strong>level header.</strong></li>
            <li><strong>Criterion cell description:</strong> contains the text descriptions for each level (column) within each criteria (row). Clicking into the criterion cell also reveals three meta data options for the editor to add <strong>cell feedback, cell notes,</strong> or make an <strong>association.</strong></li>
            <li>Once an association has been created to a criterion or criterion cell description, a double arrow will appear to signify that the association exists. To change the item associated with the criterion or criterion cell description, click on the double arrow and select a different item.</li>
        </ul>
    </p>


    <p><strong>EDIT A RUBRIC</strong>
        <ul>
            <li>Choose the <strong>associated rubric</strong> icon from the selected item card</li>
            <img srcset="/docimages/associated_rubric_icon.png 2.5x" class="k-help-img block">
            <li>From the <v-icon small>fa-solid fa-ellipsis-vertical</v-icon> kebab menu in the top right corner of the rubric window, choose <strong>Edit Rubric.</strong></li>
            <li>Review the CREATE A RUBRIC section above for edit options and refer to the RUBRIC METADATA FIELDS section for an explanation of the editable fields.</li>
        </ul>
    </p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	